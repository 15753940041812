.col {
    padding: 1em;
    /* grid-column: span 12; */
}

/* .col--nav__links.dark-mode {
    color: white;
    background: var(--darkest);
} */

.col--lightest {
    background: var(--lightest);
}

.col--light {
    background: var(--light);
}

.col--lightest.dark-mode {
    color: white;
    background: var(--darkest);
}

.col--light.dark-mode {
    color: white;
    background: var(--dark);
}

@media (max-width: 767px) {
    .sm--1 {
        grid-column: span 1;
    }
    .sm--2 {
        grid-column: span 2;
        
    }
    .sm--3 {
        grid-column: span 3;
    }
    .sm--4 {
        grid-column: span 4;
        
    }
    .sm--5 {
        grid-column: span 5;
        
    }
    .sm--6 {
        grid-column: span 6;
        
    }
    .sm--7 {
        grid-column: span 7;
        
    }
    .sm--8 {
        grid-column: span 8;
        
    }
    .sm--9 {
        grid-column: span 9;
        
    }
    .sm--10 {
        grid-column: span 10;
        
    }
    .sm--11 {
        grid-column: span 11;
        
    }
    .sm--12 {
        grid-column: span 12;

    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .md--1 {
        grid-column: span 1;
    }
    .md--2 {
        grid-column: span 2;
        
    }
    .md--3 {
        grid-column: span 3;
    }
    .md--4 {
        grid-column: span 4;
        
    }
    .md--5 {
        grid-column: span 5;
        
    }
    .md--6 {
        grid-column: span 6;
        
    }
    .md--7 {
        grid-column: span 7;
        
    }
    .md--8 {
        grid-column: span 8;
        
    }
    .md--9 {
        grid-column: span 9;
        
    }
    .md--10 {
        grid-column: span 10;
        
    }
    .md--11 {
        grid-column: span 11;
        
    }
    .md--12 {
        grid-column: span 12;

    }
}

@media (min-width:1200px) {
    .lg--1 {
        grid-column: span 1;
    }
    .lg--2 {
        grid-column: span 2;
        
    }
    .lg--3 {
        grid-column: span 3;
    }
    .lg--4 {
        grid-column: span 4;
        
    }
    .lg--5 {
        grid-column: span 5;
        
    }
    .lg--6 {
        grid-column: span 6;
        
    }
    .lg--7 {
        grid-column: span 7;
        
    }
    .lg--8 {
        grid-column: span 8;
        
    }
    .lg--9 {
        grid-column: span 9;
        
    }
    .lg--10 {
        grid-column: span 10;
        
    }
    .lg--11 {
        grid-column: span 11;
        
    }
    .lg--12 {
        grid-column: span 12;

    }
}