.social-icon {
    fill: var(--black);
    max-width: 75px;
}

/* Twitter */
.twitter {
    transition-property: fill, transform;
    transition: 275ms ease-in-out;
}
.twitter:hover,
.twitter:focus {
    fill: #1DA1F2;
    transform: scale(0.9);
    transition-property: fill, transform;
    transition: 275ms ease-in-out;
}

/* Instagram */
.instagram {
    transition-property: fill, transform;
    transition: 275ms ease-in-out;
}
.instagram:hover,
.instagram:focus {
    fill: #E4405F;
    transform: scale(0.9);
    transition-property: fill, transform;
    transition: 275ms ease-in-out;
}

/* facebook */
.facebook {
    transition-property: fill, transform;
    transition: 275ms ease-in-out;
}
.facebook:hover,
.facebook:focus {
    fill: #1877F2;
    transform: scale(0.9);
    transition-property: fill, transform;
    transition: 275ms ease-in-out;
}