form {
    display: flex;
    flex-direction: column;
}


label p {
    padding: 0 0.5em;
}

fieldset {
    display: block;
    background: white;
    margin: 0;
    margin-bottom: 0.5em;
}

legend p {
    margin: 0;
}

fieldset h4 {
    margin: 0;
}

input,
textarea {
    width: 100%;
    border: none;
}

textarea {
    min-height: 100px;
    resize: vertical;
}

input:hover,
input:focus,
input:active,
textarea:hover,
textarea:focus,
textarea:active {
    outline: none;
    transition: all 275ms ease-in-out;
}

#mailingCheck {
    cursor: pointer;
    width: 30px;
    height: 30px;
    margin: 0;
    margin-right: calc(0.5em / 0.32);
}

#contactSubmit {
    max-width: max-content;
    margin: 0 auto;
}