table {
    width: 100%;
    margin-bottom: 0.5em;
}

thead {
    color: white;
    background-color: var(--black);
}

th {
    padding: 1em 0;
}

tbody {
    background: white;
}

td {
    padding: 0.5em 0;
}

table, th, td {
    border: 1px solid var(--black);
    border-spacing: 0;
}