@font-face {
	font-family: 'Old-Standard-TT';
	src: url('../../fonts/OldStandardTT-Regular.ttf') format('woff2 supports variations');
	font-weight: regular;
	font-stretch: 25% 151%;
}

@supports (font-size-adjust: 1) {
    body {
        font-size-adjust: 0.5;
    }
}
p {
    color: rgba(119,119,119,1);
    margin: calc(1ex / 0.32) 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--black);
}
h1 {
    font-family: 'Old-Standard-TT';
    font-size: 2.5rem;
    line-height: calc(1ex / 0.42);
    margin: calc(1ex / 0.42) 0;
}
h2 {
    font-family: 'Old-Standard-TT';
    font-size: 2rem;
    line-height: calc(1ex / 0.42);
    margin: calc(1ex / 0.42) 0;
}
h3 {
    font-family: 'Old-Standard-TT';
    font-size: 1.75rem;
    line-height: calc(1ex / 0.38);
    margin: calc(1ex / 0.38) 0;
}
h4 {
    font-family: 'Old-Standard-TT';
    font-size: 1.5rem;
    line-height: calc(1ex / 0.37);
    margin: calc(1ex / 0.37) 0;
}
/* Dynamic & Smart Colours */
html {
    --red: 255;
    --green: 255;
    --blue: 255;
    --aa-brightness: ((var(--red) * 299) + (var(--green) * 587) + (var(--blue) * 114)) / 1000;
    --aa-color: calc((var(--aa-brightness) - 128) * -1000);
    background: rgb(var(--red), var(--green), var(--blue));
    color: rgb(var(--aa-color), var(--aa-color), var(--aa-color));
}
a {
    color: #006fc6;
}

.text--left {
    text-align: left;
}

.text--center {
    text-align: center;
}

.text--right {
    text-align: right;
}