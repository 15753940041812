
    header {
        display: flex;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: var(--nav-height);
        background: var(--black);
        box-shadow: 0px 5px 10px rgba(22, 22, 22, 0.3);
        z-index: 3;
    }

    .header__content {
        padding: 0 1em;
    }
    
    header p {
        color: white;
        margin: 0 auto 0 auto;
    }

    .nav__toggle {
        fill: white;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    
    .nav__links {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2;
        height: 100vh;
        margin-top: var(--nav-height);
        width: var(--nav-width);
        background: rgba(25, 25, 25, 0.975);
        transition: all 275ms ease-in-out;
        box-shadow: 5px 0 10px rgba(22, 22, 22, 0.3);
    }
    
    .nav__links.hidden {
        opacity: 0;
        transform: translateX(-100%);
        transition: all 275ms ease-in-out;
    }

    .nav__link {
        color: white;
        display: flex;
        align-items: center;
        padding: calc(var(--site-gutter) * 2) var(--site-gutter);
        transition: 275ms ease-in-out;
    }

    .nav__link:hover {
        background: rgba(25, 25, 25, 0.99);
        transition: 275ms ease-in-out;
    }

    .nav__overlay {
        position: fixed;
        display: unset;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 1;
        background: rgba(0, 0, 0, 0.3);
        transition: all 275ms ease-in-out;
    }
    
    .nav__overlay.hidden {
        display: none;
        transition: all 275ms ease-in-out;
    }

    @media (min-width: 1200px) {

    }