
section {
    background: ivory;
    display: flex;
    flex-direction: column;
    padding: 100px 0;
}

#reviews {
    padding: 3em 0;
    min-height: 500px;
    color: white;
    background: #C33764;
    background: -webkit-linear-gradient(rgba(22, 22, 22, 0.5), rgba(22, 22, 22, 0.5)), url("../../img/garden--small.webp");
    background: linear-gradient(rgba(22, 22, 22, 0.5), rgba(22, 22, 22, 0.5)), url("../../img/garden--small.webp");
    background-color: rgba;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

#reviews h4 {
    color: white;
}

#reviews p {
    color: rgb(201, 201, 201);
}

/* Will be changed */
#map {
    padding: 0;
}
#mapCanvas {
    min-height: calc(100vh - var(--nav-height));
}


@media (min-width: 767px) {
    #reviews {
        background-attachment: fixed;
        background: -webkit-linear-gradient(rgba(22, 22, 22, 0.5), rgba(22, 22, 22, 0.5)), url("../../img/garden.webp");
        background: linear-gradient(rgba(22, 22, 22, 0.5), rgba(22, 22, 22, 0.5)), url("../../img/garden.webp");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }
    #mapCanvas {
        min-height: 600px;
    }
}