/* Components */
@import url("./components/nav.css");
@import url("./components/hero.css");
@import url("./components/buttons.css");
@import url("./components/form.css");
@import url("./components/table.css");
@import url("./components/social.css");

/* Global */
@import url("./global/reset.css");
@import url("./global/variables.css");
@import url("./global/typography.css");

/* Layout */
@import url("./layout/section.css");
@import url("./layout/site-layout.css");
@import url("./layout/col.css");
@import url("./layout/row.css");
@import url("./layout/flex.css");
@import url("./layout/alignment.css");