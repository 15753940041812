.align--start {
    align-items: flex-start;
    align-content: flex-start;
}

.align--center {
    align-items: center;
    align-content: center;
}

.align--end {
    align-items: flex-end;
    align-content: flex-end;
}

.align--between {
    align-content: space-between;
}

.justify--start {
    justify-items: flex-start;
    justify-content: start;
}

.justify--center {
    justify-items: center;
    justify-content: center;
}

.justify--end {
    justify-items: end;
    justify-content: flex-end;
}

.justify--between {
    justify-content: space-between;
}