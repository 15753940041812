#hero {
    /* padding: 3em 0; */
    min-height: 100vh;
    color: white;
    background: -webkit-linear-gradient(rgba(22, 22, 22, 0.5), rgba(22, 22, 22, 0.5)), url("../../img/banner.webp");
    background: linear-gradient(rgba(22, 22, 22, 0.5), rgba(22, 22, 22, 0.5)), url("../../img/banner.webp");
    background-color: rgba;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

/* fix this */

#hero h1 {
    color: white;
}

#hero p {
    color: rgb(201, 201, 201);
}

@media (min-width: 765px) {
    #hero {
        min-height: 85vh;
        background-attachment: fixed;
    }
}