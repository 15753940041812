.row {
    display: grid;
    gap: 0.5em;
    grid-template-columns: repeat(12, minmax(5px, 1fr));
}

.row--light {
    background: var(--light);
}

.row--lightest {
    background: white;
}
