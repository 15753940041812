.btn {
    border: none;
    color: black;
    background: none;
    padding: 0.5em 2em;
    margin-bottom: 0.5em;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    transition: all 275ms ease-in-out;
}

.btn--nav {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    width: 20px;
    font-size: 1.25em;
    outline: none;
}

.btn--outline--white {
    color: white;
    background: none;
    border: 2px solid white;
    border-radius: 19px;
}

.btn--outline--white:hover,
.btn--outline--white:focus,
.btn--outline--white:active {
    color: black;
    background-color: white;
    outline: none;
    transition: all 275ms ease-in-out;
}

.btn--outline--black {
    color: black;
    background: white;
    border: 2px solid black;
    border-radius: 19px;
}

.btn--outline--black:hover,
.btn--outline--black:focus,
.btn--outline--black:active {
    color: white;
    background-color: black;
    outline: none;
    transition: all 275ms ease-in-out;
}